import { MutationTree } from 'vuex';
import Vue from 'vue';
import { DateTime } from 'luxon';

import State from './state';

const mutations: MutationTree<State> = {
    openFilter(state: State, open: boolean): void {
        Vue.set(state, 'filterOpened', open);
    },

    setRouteState(state: State, loading: boolean): void {
        Vue.set(state, 'loadingRoute', loading);
    },

    move(state: State, isNext: any): void {
        let selectedDateTemp = state.selectedDate ? DateTime.fromJSDate(state.selectedDate) : DateTime.local();
        if (isNext === null) {
            selectedDateTemp = DateTime.local();
        } else if (isNext) {
            selectedDateTemp = selectedDateTemp.plus({ [state.periodRangeType]: 1 })
        } else {
            selectedDateTemp = selectedDateTemp.minus({ [state.periodRangeType]: 1 })
        }

        state.selectedDate = selectedDateTemp.toJSDate();
    },
    changeDialogState(state: State, obj:any): void { 
        state[obj.dialog] = obj.show;
    },
};

export default mutations;
