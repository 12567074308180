import { DateTime } from 'luxon';

export default class DateHelper {
    // returns a string from a js date
    public static toFormat(date: Date | string, format = 'dd-MM-yyyy'): string {
        let returnString = '';

        if (date) { 
            if (typeof date === 'string') {
                returnString = DateTime.fromISO(date).toLocal().toFormat(format);      
            } else { 
                returnString = DateTime.fromJSDate(date).toLocal().toFormat(format);      
            }   
        } 

        return returnString.trim();
    }

    public static inputFormat(date: Date, justDate = false): string {
        if (!date) {
            return null;
        }

        const dateTimeObj = DateTime.fromJSDate(date);

        if (justDate) {
            return `${dateTimeObj.toFormat('yyyy-MM-dd')}`;
        }

        return `${dateTimeObj.toFormat('yyyy-MM-dd')}T${dateTimeObj.toFormat('HH:mm')}`;
    }
}