import { DirectiveOptions } from 'vue';
import { Vue } from 'vue-property-decorator';

let tableEl;
const defaultColor = '#1383d0';

const setColorWidth = (el: HTMLElement, parentWidth: number) => {
    const color = el.dataset.color;
    const perc = el.dataset.percentage;

    if (perc) { 
        el.style.width = parentWidth * (parseInt(perc) / 100) + 'px';   
    }

    el.style.background = color || defaultColor;
};

const ro = new ResizeObserver(entries => {
    entries.forEach(entry => {
        const cr = entry.contentRect;
        const elements: NodeListOf<Element> = entry.target.querySelectorAll('.itemProgress');

        for (const element of elements as any) { // then will pass compiler
            setColorWidth(element as HTMLElement, cr.width);
        }
    })
})

// Initialize directive.
const RowPercentage: DirectiveOptions = {
    bind(el, binding, vnode) {
        // do preparation work
        // e.g. add event listeners or expensive stuff
        // that needs to be run only once
        // `value` is the initial value

        let percentage = 0;
        let color = defaultColor;

        if (binding.value && typeof binding.value === 'object') {
            const attrValue = binding.value as any;

            if (attrValue.color) {
                color = attrValue.color;
            }

            if (attrValue.percentage) {
                percentage = attrValue.percentage;
            }
        }

        if (el) {
            Vue.nextTick(() => {
                // get first td
                tableEl = el.closest('table');
                const tr = el.closest('tr');

                if (tr) { 
                    const firstTd = tr.firstChild as HTMLElement;

                    firstTd.style.position = 'relative';

                    const div = document.createElement('div') as HTMLElement;
                    div.dataset.percentage = percentage.toString();
                    div.dataset.color = color;
                    div.classList.add('itemProgress');

                    div.style.position = 'absolute';
                    div.style.top = 'calc(100% - 2px)';
                    div.style.bottom = '0';
                    div.style.left = '0';
                    div.style.width = '0px';
                    div.style.height = '2px';

                    firstTd.appendChild(div);

                    const trWidth = tr.clientWidth;
                    const element = tr.querySelectorAll('.itemProgress')[0] as HTMLElement;

                    setColorWidth(element, trWidth);
                }

                ro.observe(tableEl);
            });
        }

    },
    update(value) {
        // do something based on the updated value
        // this will also be called for the initial value
    },
    unbind() {
        // do clean up work
        // e.g. remove event listeners added in bind()
        if (tableEl) { 
            ro.unobserve(tableEl);
        }
    }
}




export default RowPercentage;