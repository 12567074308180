import AuthStore from '@/stores/auth-store';
import AppConsts from '@/shared/application/auth';
import Guid from '@/shared/helpers/guid-helper';
import { IRestResponseDto } from '@/shared/models/shared/rest-response-dto';
import axios, { Method } from 'axios';
import applyAppTokenRefreshInterceptor from './axios-refresh-token';
import { DateTime } from 'luxon';
import { User } from '../models/dto/users';
import { TokenRequest } from '../models/dto/account';
import { NameValue } from '../models/dto/general';
import { logger } from './app-logger';

// TODO: this helper should be removed if ef fixes bug
const firstLetterKeyNameConvert = (origObj: any, lowerCase = true) => {
    const isStringArray = (x) => {
        if (Array.isArray(x)) {
            return x.every(i => (typeof i === "string"));
        }

        return false;
    }

    return Object.keys(origObj).reduce((newObj, key: any) => {
        const val = origObj[key];
        let newVal = val; // returning value is the same at first
        let newKey = '';

        // if the value of this key is an object, not a date and not an array of strings, the child object needs case converting as well
        if (val && typeof val === 'object' && typeof val.getMonth !== 'function' && !isStringArray(val)) {
            if (Array.isArray(val)) {
                newVal = [];

                val.forEach(v => {
                    newVal.push(firstLetterKeyNameConvert(v, lowerCase));
                });
            } else {
                newVal = firstLetterKeyNameConvert(val, lowerCase);
            }
        }

        if (!lowerCase && key === 'id') {
            newKey = 'Id';
        } else if (lowerCase && key === 'ID') {
            newKey = 'id';
        } else {
            newKey = (lowerCase ? key.charAt(0).toLowerCase() : key.charAt(0).toUpperCase()) + key.slice(1);
        }

        newObj[newKey] = newVal;

        return newObj;
    }, {});
}

const axiosApiInstance = axios.create();
applyAppTokenRefreshInterceptor(axiosApiInstance);

export default class AuthService {
    static swalToast(duration: number, type: string, title: string) {
        AppConsts.swalWithBootstrapButtons.fire({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: duration,
            icon: type,
            title: title,
            target: document.getElementById('app'),
        } as any);
    }

    public async getList<T>(endpoint: string): Promise<T[]> {
        return new Promise<T[]>((resolve, reject) => {
            this.get<T[]>(
                endpoint, false)
                .then((response) => {
                    if (response.content["data"]) {
                        resolve(response.content["data"]);
                    }
                    else {
                        resolve(response.content);
                    }
                });
        });
    }

    static refreshToken(): Promise<TokenRequest> { 
        return new Promise<TokenRequest>((resolve, reject) => {
            const tokenData = AuthStore.getUser();
            if (tokenData.requestToken.expiredateUTC > new Date()) {
                resolve(tokenData.requestToken);
            }
            else {
                axios.post<TokenRequest>(AppConsts.baseApiUrl + '/api/refreshtoken', AuthStore.getUser().requestToken)
                    .then(tokenRefreshResponse => {
                        if (tokenRefreshResponse && tokenRefreshResponse.data) {
                            AuthStore.refreshToken(tokenRefreshResponse.data);
                            logger.info('Token refreshed');
                            resolve(tokenRefreshResponse.data);
                        } else {
                            reject();
                        }
                    }).catch((error) => { // unable to refresh the token                   
                        reject();
                    });
            }
        });
    }

    private static successCallback<T>(response, method, url) {
        const isBadRequest = response.status >= 300;

        if (response.status === 404) {
            logger.error('404 Not found', method, url);

            this.swalToast(10000, 'error', `404 Not found`);
        }

        let content: any;

        if (response.data) {
            try {
                content = response.data;

                // TODO: should be removed when ef fixed bug. Because everything is returned with capital, lowercase first letter of all keys
                if (content && content.length) {
                    for (let i = 0; i < content.value.length; i++) {
                        content.value[i] = firstLetterKeyNameConvert(content.value[i], true);
                    }
                } else if (Object.keys(content).length > 1) {
                    content = firstLetterKeyNameConvert(content, true);
                }
            } catch (err) {
                content = response;
            }
        }

        const responseObj = {
            isError: isBadRequest,
            errors: isBadRequest ? content.errors : null,
            content: isBadRequest ? null : content && content.value ? content.value as T : content as T,
            nextLink: content ? content['@odata.nextLink'] : '',
            context: content ? content['@odata.context'] : '',
            count: content ? content['@odata.count'] : 0
        } as IRestResponseDto<T>;

        return responseObj;
    }

    private static errorCallback(errorObj, method, url) {
        let errors;
        const responseObj = {
            isError: true,
            errors: []
        };

        if (errorObj && errorObj.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            logger.log(errorObj.response.data);
            logger.log(errorObj.response.status);
            logger.log(errorObj.response.headers);

            if (errorObj.response.status === 404) {
                logger.error('404 Not found', method, url);
                this.swalToast(10000, 'error', `404 Not found`);
            }

            errors = firstLetterKeyNameConvert(JSON.parse(errorObj.response.data));
        } else if (errorObj.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            logger.log(errorObj.request);
            errors = 'Unable to connect to the server. Check your internet connection or contact DWG.';
            AuthStore.removeUser();
            window.location.href = '/login';
        } else {
            // Something happened in setting up the request that triggered an Error
            logger.log('Error', errorObj.message);
            errors = 'Unable to connect to the server. Check your internet connection or contact DWG.';
        }

        logger.saveLogInStorage({ errors, method, url });

        if (typeof errors === 'string') {
            this.swalToast(10000, 'error', errors);
        } else if (typeof errors === 'object') {
            errors.errors.forEach((e, i) => {
                const err: NameValue = Object.keys(e).length > 1 ? firstLetterKeyNameConvert(e, true) as NameValue : { name: '', value: '' };

                responseObj.errors.push(err);
            });
        }

        AppConsts.isLoading = false;

        return responseObj as any;
    }

    static makeRequest<T>(method: Method, url: string, data: any | string = '', loadingEnabled = true): Promise<IRestResponseDto<T>> {
        if (loadingEnabled) {
            AppConsts.isLoading = true;
        }

        const user: User = AuthStore.getUser();

        if (data) {
            if (url.indexOf('roles') === -1 || url.indexOf('users') === -1) {
                // TODO: ef bug workaround with firstLetterKeyNameConvert
                data = JSON.stringify(firstLetterKeyNameConvert(data, false));
            }
        }

        // perform the actual request
        return axiosApiInstance.request<IRestResponseDto<T>>({
            method: method,
            url: AppConsts.baseApiUrl + url,
            data: data,
            headers: {
                'Authorization': `Bearer ${user ? user.requestToken.accessToken : ''}`,
                'Content-Type': 'application/json'
            },
            transformResponse: (res) => {
                if (res && method === 'GET') {
                    const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
                    const content = JSON.parse(res, (key, value) => {
                        // convert date strings to date objects
                        if (typeof value === "string" && ISO_8601_FULL.test(value)) {
                            return DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
                        }
                        return value;
                    });
                    return content;
                } 
                return res;               
            }
        })
            .then((response: any) => AuthService.successCallback(response, method, url))
            .catch((errorObj: any) => AuthService.errorCallback(errorObj, method, url))
            .finally(() => AppConsts.isLoading = false);
    }

    get<T>(url: string, loadingEnabled = false): Promise<IRestResponseDto<T>> {
        const responseObj = {
            isError: false,
            errors: null,
            content: null,
            context: '',
            count: 0
        } as IRestResponseDto<T>;

        let returnContent = null;
        let nextLink = url;
        let count = null;

        const getData = (resolve, reject) => {
            AuthService.makeRequest<T>('GET', nextLink.replace(AppConsts.baseApiUrl, ''), '', loadingEnabled).then(returnObj => {
                responseObj.context = returnObj.context;
                count = returnObj.count;

                if (returnObj.isError) {
                    responseObj.isError = true;
                    responseObj.errors = returnObj.errors;

                    reject(returnObj.isError);
                } else {
                    if (returnObj.content) {
                        nextLink = returnObj.nextLink;

                        if (returnContent === null) {
                            returnContent = returnObj.content;
                        } else {
                            returnContent = returnContent.concat(returnObj.content);
                        }
                    }

                    if (returnObj.nextLink) {
                        getData(resolve, reject);
                    } else {
                        resolve(returnObj);
                    }
                }
            });
        };

        return new Promise((r, j) => {
            getData(r, j);
        }).then(() => {
            responseObj.content = returnContent as T;
            responseObj.count = count || returnContent.length;

            return responseObj;
        });
    }

    delete(url: string): Promise<IRestResponseDto<void>> {
        return AuthService.makeRequest<void>('DELETE', url);
    }

    put<T>(url: string, data: any | string): Promise<IRestResponseDto<T>> {
        return AuthService.makeRequest<T>('PUT', url, data);
    }

    post<T>(url: string, data: any | string, loadingEnabled = true): Promise<IRestResponseDto<T>> {
        return AuthService.makeRequest<T>('POST', url, data, loadingEnabled);
    }

    postOrPut<T>(url: string, data: any | string, id: string | number | undefined): Promise<IRestResponseDto<T>> {
        if (id && id !== Guid.empty) {
            return AuthService.makeRequest<T>('PUT', url, data);
        }

        return AuthService.makeRequest<T>('POST', url, data);
    }

    login<T>(url: string, data: any | string): Promise<IRestResponseDto<User>> {
        AppConsts.isLoading = true;

        let body = '';

        const headers: { [key: string]: string } = {};

        if (!data) {
            return Promise.reject({
                isError: true,
                errors: [{ name: 'LoginIncorrect', value: 'The data entered is incorrect' }],
                content: null,
                context: '',
                count: 0
            } as IRestResponseDto<User>);
        } else {
            headers['Content-Type'] = 'application/json';
            body = JSON.stringify(firstLetterKeyNameConvert(data, false));
        }

        // perform the actual request
        return axios.request<IRestResponseDto<User>>({
            method: 'POST',
            url: AppConsts.baseApiUrl + url,
            headers: headers,
            data: body,
        })
            .then((response: any) => {
                const responseObj = AuthService.successCallback<User>(response, 'POST', url);

                const obj = {
                    companyId: responseObj.content.companyId,
                    emailaddress: responseObj.content.emailaddress,
                    firstname: responseObj.content.firstname,
                    id: responseObj.content.id,
                    lastname: responseObj.content.lastname,
                    requestToken: responseObj.content.requestToken,
                    userRoles: [],
                    userSettings: []
                } as User;
    
                AuthStore.setUser(obj); // NB. differs from TMS, because tokens are returned in userobject

                AppConsts.auth.fillProps();

                return responseObj;
            })
            .catch((error) => {
                const responseObj = {
                    isError: true,
                    errors: [],
                } as IRestResponseDto<User>;

                if (error.response && error.response.data && error.response.data.errors) {
                    error.response.data.errors.forEach(e => responseObj.errors.push(e));
                }

                if (error.toString() === 'TypeError: Failed to fetch' || error.toString().indexOf('Network Error') > -1) {
                    AppConsts.swalWithBootstrapButtons.fire({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 10000,
                        icon: 'error',
                        title: 'Unable to connect to the server. Check your internet connection or contact DWG.',
                        target: document.getElementById('app'),
                    } as any);

                    if (window.location.pathname !== '/login') {
                        window.location.href = 'login';
                    }
                }

                AppConsts.isLoading = false;

                return error;
            })
            .finally(() => AppConsts.isLoading = false);
    }
}