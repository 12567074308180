export default class State {
	/* General */
	public loadingRoute = false;

	public filterOpened = false;

	public selectedDate: Date = new Date();

	public periodRangeType = 'day';

    /* Dialogs */
}
