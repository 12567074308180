//https://github.com/arunredhu/vuejs_boilerplate/tree/master/src/app/users/shared/config
/*eslint no-console: ["off"] */

/**
 * @description Logger class
 * This is responsible for logging of all kind of stuff in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced with empty methods
 * This can be extended with the help of adding Log level functionality
 */
class AppLogger {
    protected localStorageEntry = 'ItsLogger';
    public log = null;
    public debug = null;
    public info = null;
    public warn = null;
    public error = null;
    public saveLogInStorage = null;

    /**
     * @constructor AppLogger
     */
    constructor() {
        /** Initializing the configuration of logger */
        this.initLogger();
    }

    /**
     * @description Initializing the configuration such as if environment is production then all log method will be replaced with empty methods
     * except saveLogInStorage, which will be responsible for logging the important stuff in localstorage so it can be send by mail
     */
    initLogger() {
        /** Checking the environment */
        if (process.env.VUE_APP_DEBUG === 'true') {
            this.log = console.log.bind(console);

            this.debug = console.debug.bind(console);

            this.info = console.info.bind(console);

            this.warn = console.warn.bind(console);

            this.error = console.error.bind(console);

            //this.saveLogInStorage = this.error;
        } else {
            /** In case of production replace the functions definition */
            /* eslint-disable  @typescript-eslint/no-empty-function */
            this.log = this.debug = this.info = this.warn = this.error = () => { };
            /* eslint-enable  @typescript-eslint/no-empty-function */

            //this.saveLogInStorage = err => {
            //    console.error(err); //
            //};
        }

        this.saveLogInStorage = err => {
            const currentLog = localStorage.getItem(this.localStorageEntry);
            let newObj = [];

            if (currentLog) { 
                newObj = JSON.parse(currentLog);
            }

            newObj.push(err);

            localStorage.setItem(this.localStorageEntry, JSON.stringify(newObj));
        };
    }
}

/** Creating the instance of logger */
const logger = new AppLogger();

export { logger };