import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        AsideMenu: require('@/views/_components/menu/aside-menu/aside-menu.vue').default,
        TopMenu: require('@/views/_components/menu/top-menu/top-menu.vue').default
    }
})
export default class AppComponent extends AuthComponentBase {
    authenticated = false;

    async created() {
        this.authenticated = this.authStore.isSignedIn();
        this.$root.$on('authenticated', (authenticated: boolean) => {
            this.authenticated = authenticated;
        });

        if (this.authStore.isSignedIn()) {
            await this.AppConsts.auth.fillProps();

            const user = this.authStore.getUser();
            if (user) {
                this.$startSignalR(user.requestToken.accessToken);
            }
        }        
    }
}