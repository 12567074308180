//https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app

import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import AppConsts from '@/shared/application/auth';
import AuthService from './auth-service-proxy';
import { Mutex } from '../helpers/mutex';

export default {
    install(Vue) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$appHub
        const appHub = new Vue();
        Vue.prototype.$appHub = appHub;

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null;
        let startedPromise = null;
        let reconnectCount = 0;
        let manuallyClosed = false;
        let toast = null;

        Vue.prototype.$startSignalR = (jwtToken) => {
            connection = new HubConnectionBuilder()
                .withUrl(
                    `${AppConsts.baseApiUrl}/app-hub`,
                    jwtToken ? { accessTokenFactory: () => jwtToken } : null
                )
                .configureLogging(process.env.NODE_ENV === 'development' ? LogLevel.Information : LogLevel.Critical)
                .build();

            // Forward hub events through the event, so we can listen for them in the Vue components
            //connection.on('QuestionAdded', (question) => {
            //    appHub.$emit('question-added', question)
            //})
            connection.on('UpdateRoleList', () => {
                console.info('Hub message from server: UpdateRoleList');
                appHub.$emit('update-role-list');
            });

            connection.on('UpdateSensorList', () => {
                console.info('Hub message from server: UpdateSensorList');
                appHub.$emit('update-sensor-list');
            });

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start()
                    .then(() => {
                        console.info('Hub connected');
                        if (toast) {
                            toast.close();
                            toast = null;
                        }

                        if (reconnectCount > 0) {
                            // make sure permissions are set
                            // if token is expired, 404 will redirect to login
                            AppConsts.auth.fillProps();
                        }

                        reconnectCount = 0;
                    })
                    .catch(async err => {
                        console.error('Failed to connect with hub');

                        // token is expired
                        if (typeof err !== 'undefined' && err.statusCode === 401) {
                            await Mutex.dispatch(AuthService.refreshToken).then(async(res) => {
                                const tokenResponse = await res;
                                connection.stop();
                                connection = new HubConnectionBuilder()
                                    .withUrl(
                                        `${AppConsts.baseApiUrl}/app-hub`,
                                        tokenResponse.accessToken ? { accessTokenFactory: () => tokenResponse.accessToken } : null
                                    )
                                    .configureLogging(LogLevel.Information)
                                    .build();
                                start();
                            });
                        } else if (typeof connection !== 'undefined' && connection.connectionState === 0) {
                            return new Promise((resolve, reject) =>
                                setTimeout(() => {
                                    console.info('Reconnecting to hub');
                                    reconnectCount++;
                                    start().then(resolve).catch(reject);
                                }, reconnectCount < 10 ? 5000 : reconnectCount < 50 ? 60000 : 3600000));
                        }
                    });
                return startedPromise;
            }

            connection.onclose(() => {
                console.info('Hub connection closed. Manually? => ', manuallyClosed);
                if (manuallyClosed === false) {
                    toast = AppConsts.swalWithBootstrapButtons.fire({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        icon: 'info',
                        title: 'Disconnected from server',
                        target: document.getElementById('app')
                    } as any);

                    start();
                }
            })

            // Start everything
            manuallyClosed = false;
            start();
        };

        Vue.prototype.$stopSignalR = () => {
            console.info('Hub stopSignalR');

            if (!startedPromise) { return; }

            manuallyClosed = true;

            return startedPromise
                .then(() => connection.stop())
                .then(() => { startedPromise = null });
        };
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $startSignalR: any;
        $stopSignalR: any;
        $appHub: any;
    }
}
