import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import AuthStore from '@/stores/auth-store';

@Component
export default class AsideMenuComponent extends AuthComponentBase {
    drawer = false;
    isAdmin = false;
    authenticated = false;
    routes: RouteConfig[] = [];
    menuCategories: any[] = [];
    
    mounted() {
        const signedInOnLoad = this.authStore.isSignedIn();
        this.authenticated = signedInOnLoad;
        this.drawer = signedInOnLoad;

        if (this.$router && (this.$router as any).options) { 
            this.routes = (this.$router as any).options.routes;

            this.setMenuCategories();
        }

        this.$root.$on('authenticated', (authenticated: boolean) => {
            this.authenticated = authenticated;
            this.drawer = authenticated;
        });

        this.$root.$on('drawerChanged', () => {
            this.drawer = !this.drawer;
        });
    }

    setMenuCategories() {
        if (this.authenticated) {
            // first add an empty group for general items
            this.menuCategories.push({
                name: '',
                expanded: true
            });

            this.routes.forEach((route: RouteConfig) => {
                if (
                    route.meta &&
                    route.meta.showInMenu &&
                    route.meta.subHeader &&
                    !this.menuCategories.filter(cat => cat.name === route.meta.subHeader).length
                ) {
                    this.menuCategories.push({
                        name: route.meta.subHeader,
                        expanded: this.routes.filter(r => r.meta && r.meta.subHeader && route.meta && route.meta.subHeader && r.meta.subHeader === route.meta.subHeader && r.path.indexOf(window.location.pathname) > -1).length === 1 // if a route in the group is active, expand it
                    });
                }
            });
        }
    }

    menuCategoryItems(cat: string): RouteConfig[] {
        if (this.authenticated) {
            const routesForCat: RouteConfig[] = [];

            this.routes.forEach((route: RouteConfig) => {
                if (
                    route.meta &&
                    route.meta.showInMenu &&
                    ((cat === '' && !route.meta.subHeader) || route.meta.subHeader === cat) &&
                    this.correctRoutePermissions(route.meta.permissions)
                ) {
                    routesForCat.push(route);
                }
            });

            return routesForCat;
        } else {
            return [];
        }
    }

    correctRoutePermissions(routePermissions: string[]): boolean {
        if (!routePermissions || routePermissions.length === 0) {
            return true;
        } else {
            return AuthStore.isAllowedOnRoute(routePermissions, this.AppConsts.auth.grantedPermissions);
        }
    }
}