import Vue from 'vue';
import AuthService from '@/shared/application/auth-service-proxy';
import AppConsts from '@/shared/application/auth';
import Swal from 'sweetalert2';
import AuthStore from '@/stores/auth-store';
import { Component } from 'vue-property-decorator';
import Query from '../helpers/query-builder';
import DateHelper from '../helpers/date-helper';
import { ODataFilterObject } from '../models/shared/odata-filter-object';
import { FilterOperator } from '../models/shared/filter-operator';
import { logger } from './app-logger';
import { TranslationObject } from '../models/shared/translation-object';
import en from '@/assets/localizations/apexcharts/en.json';
import nl from '@/assets/localizations/apexcharts/nl.json';

let swalWithBootstrapButtons = AppConsts.swalWithBootstrapButtons;

@Component
export default class AuthComponentBase extends Vue {
    protected authService: AuthService = new AuthService();
    protected AppConsts = Vue.prototype.$config;
    protected authStore = AuthStore;
    protected dateHelper = DateHelper;
    protected requiredError = (v: any) => !!v || this.t('RequiredField');
    protected emailError = (v: any) => /.+@.+/.test(v) || this.t('EmailValidationError');
    protected get apexChartLocales() {
        return [
            en,
            nl
        ];
    }


    protected queryBuilder(queryObj: ODataFilterObject) {
        return Query.queryBuilder(queryObj);
    }

    protected returnPropType<Type>(obj: Type, key: string) {
        function prop<Type, Key extends keyof Type>(obj: Type, key: Key) {
            return obj[key];
        }

        const keys = Object.keys(obj);

        if (keys.indexOf(key) > -1) { 
            return typeof prop(obj, key as keyof Type);
        }

        return 'undefined';
    }

    protected returnFilterOperator<T>(obj: T, key: string): FilterOperator { 
        let returnOp = FilterOperator.contains;

        const propType = this.returnPropType(obj, key);

        if (propType === 'string' && key !== 'id') { 
            returnOp = FilterOperator.contains;
        } else {
            returnOp = FilterOperator.eq;
        }

        return returnOp;
    }

    protected swalToast(duration: number, type: string, title: string) {
        swalWithBootstrapButtons.fire({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: duration,
            icon: type,
            title: title,
            target: document.getElementById('app'),
        } as any);
    }

    protected swalConfirm(title: string, isDelete = false, confirmTxt?: string) {
        let confirm: string = confirmTxt ? confirmTxt : this.t('Confirm');

        if (isDelete) {
            confirm = `<span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mr-2 mdi mdi-delete-outline theme--light" style="font-size: 16px;"></i> ${confirmTxt}</span>`;

            swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'float-right ml-5 v-btn v-btn--contained theme--light v-size--default error',
                    cancelButton: 'float-right v-btn v-btn--flat v-btn--text theme--light v-size--default grey--text text--darken-3',
                },
                buttonsStyling: false,
                willOpen: function(el) {
                    el.classList.remove('swal2-styled');
                }
            });
        }

        return swalWithBootstrapButtons.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: this.t('Cancel'),
            target: document.getElementById('app'),
        } as any);
    }

    protected swalAlert(type: string, html: string) {
        swalWithBootstrapButtons.fire({
            html: html,
            icon: type,
            showConfirmButton: false,
            target: document.getElementById('app'),
        } as any);
    }

    protected passwordMatchError(password: string, passwordRepeat: string) {
        return (password === passwordRepeat) ? '' : this.t('PasswordsMustMatch');
    }

    protected t(key: string, fullObject?: any): string { // if the fullobject is defined, the key references the property to translate
        if (key && fullObject && fullObject.translation) { // return value from translations column, if available                      
            let translationobj: TranslationObject[] = [];
            try {
                translationobj = JSON.parse(fullObject.translation);
            } catch (e) {
                logger.error('Cannot parse translations: ', fullObject.translation);
            }

            if (translationobj.length) {
                const translationObject: TranslationObject = translationobj.find(x => x.key === key);

                if (translationObject) {
                    const requestedTranslation = translationObject.translations.find(x => x.lang === this.$i18n.locale);

                    if (requestedTranslation) {
                        return requestedTranslation.translation;
                    }
                }
            }

            return fullObject[key]; // fallback result
        } else if (key && fullObject) { 
            return fullObject[key];
        } else if (key && !fullObject) { 
            const keyFirstUp = key.charAt(0).toUpperCase() + key.slice(1);
            return this.$t(keyFirstUp).toString();
        }

        return '';
    }
}