import { DateTime } from 'luxon';

// returns a string from a js date
// use example: {{ item.startTime | date('dd-MM-yyyy HH:mm') }}
export default (date: Date | string, format = 'dd-MM-yyyy') => {
    let returnString = '';

    if (date) {
        if (typeof date === 'string') {
            returnString = DateTime.fromISO(date).toLocal().toFormat(format);
        } else {
            returnString = DateTime.fromJSDate(date).toLocal().toFormat(format);
        }
    }

    return returnString.trim();
};
