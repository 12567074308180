import AuthStore from '@/stores/auth-store';
import { TokenRequest } from '../models/dto/account';
import AuthService from './auth-service-proxy';
import { Mutex } from '../helpers/mutex';

const shouldIntercept = (error) => {
    try {
        return error.response.status === 401;
    } catch (e) {
        return false;
    }
};

const handleTokenRefresh = () => {
    return Mutex.dispatch(AuthService.refreshToken);
};

const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;
};

export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        shouldIntercept,
        ...customOptions
    };
    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const interceptor = (error) => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);
                return axiosClient.request(originalRequest);
            }).catch(() => {
                return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        /* eslint-disable  no-async-promise-executor */
        return new Promise(async(resolve, reject) => {
            await options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then(async(res: Promise<TokenRequest>) => {
                    const tokenData = await res;
                    options.attachTokenToRequest(originalRequest, tokenData.accessToken);
                    processQueue(null, tokenData.accessToken);
                    resolve(axiosClient.request(originalRequest));                                      
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                    // if the connection is manually disconnected, redirect to login
                    if (typeof error.response !== 'undefined' && error.response.indexOf('Network Error') === -1) {
                        AuthStore.removeUser();
                        window.location.href = '/login';
                    }
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
        /* eslint-enable  no-async-promise-executor */
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};